define([
    'prop-types',
    'lodash',
    'componentsCore',
    'coreUtils',
    'santa-components',
    'reactDOM',
    'dialogs/inputWithValidation/inputWithValidation',
    'components'
], function (
    PropTypes,
    _,
    componentsCore,
    coreUtils,
    santaComponents,
    ReactDOM,
    inputWithValidation,
    components
) {
    'use strict';

    const siteButton = components.siteButton;
    const {INTERACTIONS} = coreUtils.loggingUtils.fedopsLogger;
    const AVAILABLE_VENDORS = ['google', 'facebook'];
    const VENDOR_CONFIG_MAP = {
        google: 'socialLoginGoogleEnabled',
        facebook: 'socialLoginFacebookEnabled'
    };
    const MSG_TYPES = {
        PAGE_READY: 'page-ready',
        AUTH_START: 'auth-start',
        AUTH_TOKEN: 'auth-token',
        AUTH_DONE: 'auth-done'
    };
    const POLICIES_MODEL_KEYS = ['termsOfUse', 'privacyPolicy'];

    const PRIVACY_STATUS = {
        PRIVATE: 'PRIVATE',
        PUBLIC: 'PUBLIC'
    };

    const WIX_INTERNAL_AUTH_EXPOSURE = 'WixInternal';

    function getView(isMobileView) {
        return isMobileView ? 'mobile' : 'desktop';
    }

    return {
        PRIVACY_STATUS,

        PASS_MIN_LEN: 4,
        PASS_MAX_LEN: 100,

        propTypes: _.assign({
            smSettings: santaComponents.santaTypesDefinitions.SiteMembersSantaTypes.smSettings.isRequired,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView.isRequired,
            isPreviewMode: santaComponents.santaTypesDefinitions.isPreviewMode.isRequired,
            isSiteMembersDialogsOpenAllowed: santaComponents.santaTypesDefinitions.SiteMembersSantaTypes.isSiteMembersDialogsOpenAllowed,
            isPremiumUser: santaComponents.santaTypesDefinitions.isPremiumUser.isRequired,
            siteMembersUrl: santaComponents.santaTypesDefinitions.SiteMembersSantaTypes.siteMembersUrl.isRequired,
            metaSiteId: santaComponents.santaTypesDefinitions.RendererModel.metaSiteId.isRequired,
            biVisitorId: santaComponents.santaTypesDefinitions.biVisitorId,
            windowKeyboardEvent: santaComponents.santaTypesDefinitions.SiteAspects.windowKeyboardEvent,
            exitFullScreenMode: santaComponents.santaTypesDefinitions.exitFullScreenMode,
            enterFullScreenMode: santaComponents.santaTypesDefinitions.enterFullScreenMode,
            styleId: santaComponents.santaTypesDefinitions.Component.styleId,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
            notClosable: PropTypes.bool,
            onCloseDialogCallback: PropTypes.func,
            onSubmitCallback: PropTypes.func,
            language: PropTypes.string,
            translations: PropTypes.object,
            navigateToRenderedLink: santaComponents.santaTypesDefinitions.Navigation.navigateToRenderedLink.isRequired,
            rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo.isRequired,
            smCollectionExposure: PropTypes.string,
            smCollectionId: PropTypes.string
        }, santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(inputWithValidation), santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(siteButton)),

        mixins: [santaComponents.mixins.animationsMixin, componentsCore.mixins.createChildComponentMixin],

        getInitialState() {
            this.canOpenSiteMembersDialogs = this.props.isSiteMembersDialogsOpenAllowed;
            const isInternalAuth = this.props.smCollectionExposure === WIX_INTERNAL_AUTH_EXPOSURE;
            return {
                showComponent: true,
                errMsg: '',
                oAuthErrMsg: '',
                $view: getView(this.props.isMobileView),
                socialLoginEnabled: this.isSocialLogin(),
                socialLoginIframeReady: false,
                showCommunityText: false,
                $previewMode: this.props.isPreviewMode ? 'previewMode' : '',
                $canBeClosed: !this.props.notClosable ? 'canBeClosed' : '',
                $dialogLoader: '',
                $wixInternalAuth: isInternalAuth ? 'wixInternalAuth' : ''
            };
        },

        componentWillMount() {
            if (!this.props.notClosable && this.props.windowKeyboardEvent) {
                this.props.windowKeyboardEvent.registerToEscapeKey(this);
            }
        },

        componentWillReceiveProps(nextProps) {
            if (this.canOpenSiteMembersDialogs && this.canOpenSiteMembersDialogs !== nextProps.isSiteMembersDialogsOpenAllowed) {
                this.closeDialog();
            } else {
                const view = getView(nextProps.isMobileView);
                if (this.state.$view !== view) {
                    this.setState({$view: view});
                }
            }
        },

        componentWillUnmount() {
            if (this.props.windowKeyboardEvent) {
                this.props.windowKeyboardEvent.unRegisterKeys(this);
            }
            this.props.exitFullScreenMode();
        },

        updateSocialIframePrivacyStatus() {
            const socialLoginIframe = this._getSocialIframeRef();
            const [socialLoginUrl] = socialLoginIframe.src.split('#');
            socialLoginIframe.src = `${socialLoginUrl}#privacyStatus=${this.getJoinCommunityStatus()}`;
        },

        getJoinCommunityStatus() {
            if (this.refs[this.joinCommunityByDefaultCheckboxRef]) {
                return this.refs[this.joinCommunityByDefaultCheckboxRef].checked ? PRIVACY_STATUS.PUBLIC : PRIVACY_STATUS.PRIVATE;
            }
            return PRIVACY_STATUS.PRIVATE;
        },

        onEscapeKey() {
            const userAction = true;
            this.closeDialog(userAction);
        },

        componentDidMount() {
            this.animate('dialog', 'FadeIn', 0.5, 0);
            this.props.enterFullScreenMode();
            const focusable = (this.getFocusElement && this.getFocusElement()) ||
                this.refs.okButton ||
                (this.refs.blockingLayer && this.refs.xButton);
            if (focusable) {
                focusable.focus();
            }
        },

        onClickCloseButton() {
            const userAction = true;
            this.closeDialog(userAction);
        },

        closeDialog(isUserAction) {
            if (this.props.onCloseDialogCallback) {
                this.props.onCloseDialogCallback(this, false, isUserAction);
            } else {
                this.performCloseDialog();
            }
        },

        performCloseDialog(additionalOnCompleteCallback) {
            this.animate('dialog', 'FadeOut', 0.5, 0, null, {
                onComplete() {
                    if (additionalOnCompleteCallback) {
                        additionalOnCompleteCallback();
                    }
                }
            });
        },

        submit() {
            this.setState({oAuthErrMsg: ''});
            if (this.shouldBlockSubmit && this.shouldBlockSubmit()) {
                this.blockSubmit(ReactDOM.findDOMNode(this.refs.submitButton));
                return;
            }
            let isValid = true;
            if (this.validateBeforeSubmit) {
                isValid = this.validateBeforeSubmit();
            }
            if (isValid) {
                if (this.props.onSubmitCallback) {
                    this.props.onSubmitCallback(_.merge(this.getDataToSubmit(), {lang: this.props.language || 'en'}), this);
                } else {
                    coreUtils.log.error('dialogMixin: this.props.onSubmitCallback is not defined');
                }
            }
        },

        submitOnEnterKeyPress(evt) {
            if (evt.key === 'Enter') {
                this.submit();
            }
        },

        getErrorMessage() {
            return this.state.errMsg;
        },

        _toErrorKey(errorCode) {
            const prefix = 'SMForm_Error_';
            let errorKey = _.startsWith(errorCode, prefix) ? errorCode : prefix + errorCode;
            if (_.includes(errorKey, '-')) {
                errorKey = errorKey.replace('-', '');
            }
            return errorKey;
        },

        setErrorMessageByCode(errorCode) {
            if (errorCode) {
                this.setErrorMessage(this._toErrorKey(errorCode));
            } else {
                this.setState({errMsg: ''});
            }
        },

        setOuathErrorMessageByCode(errorCode) {
            this.setState({
                oAuthErrMsg: errorCode ? this.getText(this.props.language, this._toErrorKey(errorCode)) : ''
            });
        },

        setErrorMessage(errorKey) {
            const text = this.getText(this.props.language, errorKey);
            this.setState({errMsg: text});
        },

        shouldDialogBeClosed() {
            return !this.state.showComponent;
        },

        getCloseDialogSkinProperties() {
            return {
                '': {
                    style: {display: 'none'}
                }
            };
        },

        getMobileSocialLoginClass() {
            return this.state.socialLoginEnabled ? 'socialLoginMode' : 'emailLoginMode';
        },

        _isAsciiOnlyInput(value) {
            const length = value.length;
            const maxLength = this.PASS_MAX_LEN;
            for (let i = 0; i < length && i < maxLength; i++) {
                if (value.charCodeAt(i) > 127) {
                    return false;
                }
            }
            return true;
        },


        _getEmailValidator(lang) {
            return function (email) {
                if (email.length === 0) {
                    return this.getText(lang, 'SMForm_Error_Email_Blank');
                }
                if (!coreUtils.validationUtils.isValidEmail(email)) {
                    return this.getText(lang, 'SMForm_Error_Email_Invalid');
                }
                return false;
            }.bind(this);
        },

        _getPasswordValidator(lang) {
            return function (password) {
                if (password.length === 0) {
                    return this.getText(lang, 'SMForm_Error_Password_Blank');
                }
                if (password.length < this.PASS_MIN_LEN || password.length > this.PASS_MAX_LEN) {
                    return this.getText(lang, 'SMForm_Error_Password_Length').replace('{0}', this.PASS_MIN_LEN).replace('{1}', this.PASS_MAX_LEN);
                }
                if (!this._isAsciiOnlyInput(password)) {
                    return this.getText(lang, 'SMForm_Error_Non_Ascii_Chars');
                }
                return false;
            }.bind(this);
        },

        _onInputChange() {
            this.setErrorMessageByCode('');
        },

        createEmailInput(emailProperties, extraProps) {
            const lang = emailProperties.language;
            const inputTitleText = this.getText(lang, emailProperties.inputTitleKey);
            const emailValidator = this._getEmailValidator(lang);

            const inputProperties = {
                refId: emailProperties.refId,
                inputTitleText,
                validators: [emailValidator],
                type: 'email',
                defaultValue: emailProperties.defaultValue || '',
                trimValue: true
            };
            return this.createInputWithValidation(inputProperties, extraProps);
        },

        createPasswordInput(passwordProperties, extraProps) {
            const lang = passwordProperties.language;

            const inputTitleText = this.getText(lang, passwordProperties.inputTitleKey);
            const passwordValidator = passwordProperties.overrideValidators || [this._getPasswordValidator(lang)];

            const inputProperties = {
                refId: passwordProperties.refId,
                inputTitleText,
                validators: passwordValidator,
                type: 'password',
                defaultValue: passwordProperties.defaultValue
            };

            return this.createInputWithValidation(inputProperties, extraProps);
        },

        createInputWithValidation(inputProperties, extraProps) {
            inputProperties.validators = inputProperties.validators || []; // eslint-disable-line santa/no-side-effects
            extraProps = extraProps || {};

            const defaultProps = {
                'lazyValidation': true,
                'validators': inputProperties.validators,
                'label': inputProperties.inputTitleText,
                'ref': inputProperties.refId,
                'defaultValue': inputProperties.defaultValue,
                'type': inputProperties.type,
                'onChange': this._onInputChange,
                'trimValue': inputProperties.trimValue
            };
            _.merge(defaultProps, extraProps);
            return this.createChildComponent(
                {id: inputProperties.refId},
                'wysiwyg.components.viewer.inputs.InputWithValidation',
                'inputWithValidation',
                defaultProps
            );
        },

        getText(lang, textType, textParams, fallback) {
            if (!this.props.translations) {
                return '';
            }
            let text = this.props.translations[textType] ||
                fallback || this.props.translations.SMForm_Error_General_Err;

            if (textParams) {
                _.forEach(textParams, function (val, index) {
                    text = text.replace(`{${index}}`, val);
                });
            }

            return text;
        },

        createXButton() {
            return {
                'aria-label': this.getText(this.props.language, 'dialogMixinTranslations_Close_Dialog', void 0, 'close dialog'),
                onClick: this.onClickCloseButton,
                onKeyDown: componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceOrEnterButton
            };
        },

        createContent() {
            return {
                className: this.getErrorMessage() ? `${this.props.styleId}_validationError` : '',
                onKeyPress: this.submitOnEnterKeyPress
            };
        },

        createSubmitButton(btnName, btnLabel) {
            const buttonData = {
                label: btnLabel || 'GO',
                id: 'ok'
            };

            const compProp = {
                align: 'center'
            };

            const skinPartName = btnName;

            return this.createChildComponent(
                buttonData,
                'wysiwyg.viewer.components.SiteButton',
                skinPartName,
                {
                    skinPart: skinPartName,
                    styleId: 'b1',
                    compProp,
                    style: {position: 'relative', height: '100%', width: '100%'},
                    scale: 0.5
                }
            );
        },

        _getSocialIframeRef() {
            return this.state.socialLoginEnabled ?
                this.refs[this.socialLoginIframeRef] : this.refs[this.socialLoginIframeHorizontalRef];
        },

        _onTokenReceived(msg) {
            if (!msg.token || !_.isFunction(this.props.onTokenMessage)) {
                return;
            }
            const iframe = this._getSocialIframeRef();
            this.props.onTokenMessage(msg.token, msg.vendor, iframe.contentWindow, this);
        },

        _onSocialLoginDone(msg) {
            if (msg.error) {
                this.endLoader();
                this.setOuathErrorMessageByCode(msg.error);
                return;
            }
            const iframe = this.refs[this.socialLoginIframeRef];
            this.props.onBackendSocialLogin(this, iframe.contentWindow, msg.vendor, JSON.parse(msg.data));
        },

        _onSocialLoginIframeLoaded() {
            const iframeReadyState = {
                socialLoginIframeReady: true
            };

            this.setState(iframeReadyState);
        },

        onSocialLoginIframeMessage(msg) {
            switch (msg.type) {
                case MSG_TYPES.PAGE_READY:
                    this._onSocialLoginIframeLoaded();
                    break;
                case MSG_TYPES.AUTH_START:
                    coreUtils.loggingUtils.fedopsLogger.interactionStarted(INTERACTIONS.MEMBERS.SOCIAL_APP_LOGIN);
                    break;
                case MSG_TYPES.AUTH_TOKEN:
                    coreUtils.loggingUtils.fedopsLogger.interactionEnded(INTERACTIONS.MEMBERS.SOCIAL_APP_LOGIN);
                    this._onTokenReceived(msg);
                    break;
                case MSG_TYPES.AUTH_DONE:
                    coreUtils.loggingUtils.fedopsLogger.interactionEnded(INTERACTIONS.MEMBERS.SOCIAL_APP_LOGIN);
                    this._onSocialLoginDone(msg);
                    break;
            }
        },

        getSocialLoginSkinParts(mode, lang, translationKeys) { //eslint-disable-line complexity
            const isSocialLoginReady = this.state.socialLoginIframeReady;
            const enabledVendors = AVAILABLE_VENDORS.filter(vendor => _.get(this.props.smSettings, VENDOR_CONFIG_MAP[vendor]));
            const shouldDisplayIframe = this.state.socialLoginEnabled;
            const shouldDisplayIframeHorizontal = !this.state.socialLoginEnabled;

            return {
                'socialLoginWrapper': {},
                'socialLoginIframe': {
                    src: this._buildIframeSrc({
                        mode,
                        lang,
                        extraCss: 'svg-style',
                        vendors: enabledVendors.join(',')
                    }),
                    frameBorder: '0',
                    scrolling: 'no',
                    allowtransparency: 'true',
                    style: {display: shouldDisplayIframe ? 'inline' : 'none'},
                    height: enabledVendors.length * 75,
                    className: isSocialLoginReady ? `${this.props.styleId}_ready` : ''
                },
                'socialLoginIframeHorizontal': {
                    src: this._buildIframeSrc({
                        mode,
                        lang,
                        extraCss: 'svg-style horizontal',
                        vendors: enabledVendors.join(',')
                    }),
                    frameBorder: '0',
                    scrolling: 'no',
                    allowtransparency: 'true',
                    height: 30,
                    style: {display: shouldDisplayIframeHorizontal ? 'inline' : 'none'},
                    className: isSocialLoginReady ? `${this.props.styleId}_ready` : ''
                },
                'socialLoginLoader': isSocialLoginReady ? null : {className: 'circle-preloader'},
                'switchToEmailLink': {
                    children: this.getText(lang, translationKeys.switchEmail),
                    onClick: this.toggleSocialLoginDisplayState
                },
                'switchToSocialLink': {
                    className: `${this.props.styleId}_${this.isSocialLogin ? this.getMobileSocialLoginClass() : ''}`,
                    onClick: this.toggleSocialLoginDisplayState
                },
                'socialLoginErrorMsg': {
                    className: this.props.styleId + (this.state.oAuthErrMsg ? '_enabled' : ''),
                    children: this.state.oAuthErrMsg
                }
            };
        },

        isSocialLogin() {
            return !!(this.props.smSettings.socialLoginGoogleEnabled || this.props.smSettings.socialLoginFacebookEnabled);
        },

        shouldDisplayTermsFooter() {
            return _.some(POLICIES_MODEL_KEYS.map(key =>
                this.props.smSettings[key] &&
                this.props.smSettings[key].enabled &&
                this.props.smSettings[key].link
            ));
        },

        enableSocialLoginOnIframeReady() {
            this.setState({
                socialLoginIframeReady: false,
                socialLoginEnabled: !this.state.socialLoginEnabled
            });
        },

        toggleSocialLoginDisplayState() {
            this.setState({
                oAuthErrMsg: '',
                socialLoginEnabled: !this.state.socialLoginEnabled
            });
            const focusable = this.getFocusElement && this.getFocusElement();
            if (focusable) {
                focusable.focus();
            }
        },

        startLoader() {
            this.setState({
                $dialogLoader: 'dialogLoader'
            });
        },

        endLoader() {
            this.setState({
                $dialogLoader: ''
            });
        },

        getDialogPreloaderSkinProperties() {
            return {className: 'circle-preloader'};
        },

        handlePolicyLink(policy) {
            const policyLink = _.get(this.props.smSettings, [policy, 'linkData']);

            if (!policyLink) {
                return;
            }

            const linkVal = coreUtils.linkRenderer.renderLink(policyLink, this.props.linkRenderInfo, this.props.rootNavigationInfo);
            const policyLinkData = coreUtils.wixUrlParser.parseUrl(this.props.linkRenderInfo, coreUtils.linkRenderer.getLinkUrlFromLinkProps(linkVal));

            if (!policyLinkData) {
                return;
            }

            const anchorData = policyLink.anchorDataId;
            _.assign(policyLinkData, {anchorData});

            this.closeDialog();
            if (anchorData) {
                this.props.scrollToAnchor(anchorData);
            } else {
                _.assign(linkVal, {'target': '_blank', 'data-type': 'external'});
                this.props.navigateToRenderedLink(linkVal);
            }
        },

        _buildIframeSrc(config) {
            const {metaSiteId, biVisitorId, siteMembersUrl, smCollectionId} = this.props;
            if (!metaSiteId) {
                return '';
            }

            const params = {
                mode: config.mode,
                lang: config.lang,
                vendors: config.vendors,
                extraCss: config.extraCss,
                visitorId: biVisitorId,
                collectionId: smCollectionId,
                svSession: coreUtils.cookieUtils.getCookie('svSession')
            };
            const queryParams = Object.keys(params)
                .filter(key => params[key])
                .map(key => `${key}=${params[key]}`)
                .join('&');

            const templatePathUrl = `${siteMembersUrl.replace(/^(https|http):/i, '')}/view/social/frame/${metaSiteId}`;
            return `${templatePathUrl}?${queryParams}#privacyStatus=${this.getJoinCommunityStatus()}`;
        }
    };
});
